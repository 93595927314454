<template>
  <div
    class="tab-options"
    :class="`step-${activeTab}`"
    v-if="tabStep === 'steps'"
  >
    <div
      v-for="(step, stepIndex) of getEnableStep()"
      :key="stepIndex"
      class="tab"
      :class="{
        active: activeTab === step.index,
        enabled: step.index <= controllerMenu,
      }"
      @click="selectTab(step.alias)"
    >
      <div class="d-flex">
        <span class="number" :class="{ done: activeTab > step.index }">
          <i class="fas fa-check" v-if="activeTab > step.index && isMobile" />
          <span v-else>{{ stepIndex + 1 }}</span>
        </span>
        <div class="d-flex flex-column">
          <p class="name">{{ step.description }}</p>

          <div
            class="d-flex align-center"
            v-if="step.subdescriptionType == 'text'"
          >
            <p v-if="step.index !== 5">
              {{ step.subdescription || '' }}
            </p>
            <p
              v-else
              :class="{ 'tag-number': step.index === 5 && step.subdescription }"
              v-for="item in step.subdescription"
              :key="item"
            >
              {{ item }}
              <!-- <span v-if="step.index === 5 && step.subdescription">meses</span> -->
            </p>
          </div>
          <div v-else-if="step.subdescriptionType == 'tags'" class="tags">
            <div
              v-for="(item, itemIndex) of buildTagsByStep(step.index, campaign)"
              :key="itemIndex"
              class="tag"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import buildTagsHelper from '@/mixins/buildTagsHelper.js';

const STEPS = {
  NAME_STEP: 1,
  TRIGGER_STEP: 2,
  FIRING_STEP: 3,
  DELIVERY_STEP: 4,
  SEGMENTATION_STEP: 5,
  ANALYSIS_STEP: 6,
};

const FIRING_TYPE = {
  CR: 'Conversões recorrentes',
  CU: 'Conversão única',
  RM: 'Recorrente no mês',
  RS: 'Recorrente na semana',
  PN: 'Pontual',
};

const SEGMENT_NAME = {
  behavioralDaysOff: 'Dias sem pedir',
  behavioralBirthday: 'Aniversariante',
  clientAgeRange: 'Faixa etária',
  clientRegisterTimer: 'Tempo de cadastro',
  clientAverageTicket: 'Ticket médio',
  clientOrderQuantity: 'Quantidade de conversões',
  clientTotalSpent: 'Total gasto',
  geographicDistrict: 'Região',
  regionRange: 'Região',
  weatherPhotography: 'Fotografia climática',
};

export default {
  props: {
    activeTab: { type: Number, default: 1 },
    campaign: { type: Object },
    tabStep: { type: String, default: 'steps' },
  },

  data() {
    return {
      controllerMenu: 0,
      STEPS,
      tabs: {
        NAME_STEP: {
          alias: 'NAME_STEP',
          index: 1,
          description: 'Nome da campanha',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        TRIGGER_STEP: {
          alias: 'TRIGGER_STEP',
          index: 2,
          description: 'Gatilho',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        FIRING_STEP: {
          alias: 'FIRING_STEP',
          index: 3,
          description: 'Disparo',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        DELIVERY_STEP: {
          alias: 'DELIVERY_STEP',
          index: 4,
          description: 'Método de envio',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        SEGMENTATION_STEP: {
          alias: 'SEGMENTATION_STEP',
          index: 5,
          description: 'Segmentação',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        ANALYSIS_STEP: {
          alias: 'ANALYSIS_STEP',
          index: 6,
          description: 'Análise',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
      },
    };
  },
  mixins: [buildTagsHelper],
  methods: {
    typeSendConfig(campaign) {
      const typeSendProcess = campaign.id === undefined ? '' : 'SMS';
      return typeSendProcess;
    },
    getEnableStep() {
      const tabs = JSON.parse(JSON.stringify(this.tabs));

      return Object.values(tabs).filter((step) => step.enabled);
    },
    selectTab(tabAlias) {
      if (
        this.tabs[tabAlias].index <= (Object.keys(this.campaign) || []).length
      ) {
        this.$emit('selectTab', {
          tabAlias,
          tabIndex: this.tabs[tabAlias].index,
        });
      }
    },
    getDeliveryName(sendType) {
      switch (sendType) {
        case 'sms':
          return 'SMS';
        case 'whatsapp':
          return 'Whatsapp';
        case 'email':
          return 'E-mail';
        default:
          return '';
      }
    },

    buildTagsByStep ( stepIndex, campaign ) {
      let tags = [];
      switch (stepIndex) {
        case this.STEPS.TRIGGER_STEP:
          tags = this.buildTriggerTags(campaign);
          break;
        case this.STEPS.SEGMENTATION_STEP:
          tags = this.buildSegmentationTags(campaign.rules);
          break;
        case this.STEPS.FIRING_STEP:
          tags = this.buildFiringTags(campaign);
          break;
      }

      return tags;
    },
    
    buildSegmentationTags(segmentations = []) {
      let segments = [];
      segmentations.forEach((s) => {
        const { value, type } = s;
        if (value !== null) {
          if (
            Object.keys(value).includes('min') ||
            Object.keys(value).includes('max')
          ) {
            segments.push(`${SEGMENT_NAME[type]} (${value.min}-${value.max})`);
          } else if (type === SEGMENT_NAME[type]) {
            segments.push(`${value.address}`);
          } else if (value.minimum) {
            segments.push(`${SEGMENT_NAME[type]} +(${value.minimum})`);
          } else if (value.exact) {
            segments.push(`${SEGMENT_NAME[type]} (${value.exact})`);
          } else if (type == 'regionRange') {
            const configs = value;
            segments.push(...configs.map((s) => {
                if(s.cities.length == 0) {
                    return s.state;
                } else if(s.cities.length == 1) {
                    const neighborhoods = s.cities[0].neighborhoods.length > 0 ? ` (${s.cities[0].neighborhoods.length})` : '';
                    return `${s.cities[0].city} - ${s.state}${neighborhoods}`;
                } else {
                    return `${s.state} (${s.cities.length})`;
                }
            }));            
          } else {
            segments.push(`${SEGMENT_NAME[type]} `);
          }

          if (value.isLast && value.field) {
            switch (value.field) {
              case '1':
                segments.push(`No(s) último(s) ${value.isLast} dia(s)`);
                break;
              case '2':
                segments.push(`No(s) último(s) ${value.isLast} mes(es)`);
                break;
              case '3':
                segments.push(`No(s) último(s) ${value.isLast} ano(s)`);
                break;
            }
          }
        } else {
          segments.push(`${SEGMENT_NAME[type]}`);
        }
      });
      return segments;
    },
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      handler({ campaign }) {
        this.controllerMenu = (Object.keys(campaign) || []).length;

        if (campaign.type === 'PN') {
          this.tabs.FIRING_STEP.enabled = false;
        } else {
          this.tabs.FIRING_STEP.enabled = true;
        }

        const { configs } = campaign;
        this.tabs.NAME_STEP.subdescription = campaign.name;
        this.tabs.FIRING_STEP.subdescription = FIRING_TYPE[campaign.type];
        this.tabs.DELIVERY_STEP.subdescription = this.getDeliveryName(
          campaign.type_send
        );
        this.tabs.SEGMENTATION_STEP.subdescription =
          campaign.rules && campaign.rules.length > 0
            ? this.buildSegmentationTags(campaign.rules)
            : [];
        if (campaign.initial_flag === 1) {
          this.tabs.ANALYSIS_STEP.subdescription = 'Sem análise';
        } else if (campaign.initial_flag === 4) {
          this.tabs.ANALYSIS_STEP.subdescription = 'Toda a história';
        } else if (campaign.initial_flag !== undefined) {
          this.tabs.ANALYSIS_STEP.subdescription = 'Usuários Ativos';
          if (configs.createdAtMonthsBefore) {
            this.tabs.ANALYSIS_STEP.subdescription.push(
              `${configs.createdAtMonthsBefore} ${
                configs.createdAtMonthsBefore > 1 ? 'meses' : 'mês'
              } atrás`
            );
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.tab-container .tab-options {
  display: flex;
  flex-direction: column;
  min-width: 235px;
  position: relative;
  background: var(--box);
  border-radius: 5px;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .tab-container .tab-options {
    width: 100%;
    position: relative;
  }
}

.tab-container .tab-options .tab {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.1s ease;
  border-radius: 10px;
}

.tab-container .tab-options .tab:not(.enabled) {
  cursor: not-allowed;
}

.tab-container .tab-options .tab .number {
  border-radius: 50%;
  border: 1px solid #6f767e59;
  font-weight: bold;
  color: #aaaaab;
  font-size: 12px;
  margin-right: 17px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #292e32;
}
@media screen and (max-width: 991px) {
  .tab-container .tab-options .tab {
    min-height: 80px;
    padding: 20px;
    transition: none;
  }
  .tab-container .tab-options:after {
    content: '';
    background: rgba(111, 118, 126, 0.25);
    height: calc(100% - 60px);
    width: 1px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    left: 35px;
  }
  .tab-container .tab-options .tab .number {
    z-index: 9;
  }
  .tab-container .tab-options .tab .number.done i {
    background: #4c4cff;
    border-radius: 50%;
    color: #fff;
    width: 12px;
    height: 12px;
    font-size: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tab-container .tab-options .tab .name {
  font-size: 14px;
  line-height: 17px;
  color: #999;
  margin-top: 7px;
}
.tab-container .tab-options .tab p:not(.name, .tag-number) {
  color: rgba(170, 170, 171, 0.7);
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}
.tab-container .tab-options .tab:not(.active).enabled:hover {
  background: #292e32;
}
.tags {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.tags .tag {
  color: #aaaaabf2;
  padding: 5px 15px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

@media screen and (max-width: 991px) {
  .tab-container .tab:after {
    content: '\f054';
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    right: 25px;
    font-size: 12px;
    color: #8c8c8c;
  }
  .tab-container .tab-content {
    width: 100%;
    margin: 0;
    position: relative;
    padding: 17px 20px;
  }
}
@media screen and (min-width: 992px) {
  .tab-container .tab.active {
    background: #2e343873;
    border-radius: 10px;
  }
  .tab-container .tab-options .tab:not(:last-child) {
    margin-bottom: 15px;
  }
  .tab-container .tab-options .tab.active .number {
    border-color: rgba(76, 76, 255, 0.35);
    color: var(--default);
  }
  .tab-container .tab-options .tab.active .name {
    color: var(--default);
    font-weight: bold;
  }
  .tab-container .tab-options .tab.active p:not(.name) {
    font-weight: bold;
  }
}

@media screen and (max-width: 991px) {
  .tab-container .tab-options .tab .name {
    color: rgba(170, 170, 171, 0.85);
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
  .tab-container .tab-options .tab p:not(.name) {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #aaaaabf2;
  }
}
.tag-number {
  color: rgba(170, 170, 171, 0.7);
  padding: 5px 15px;
  font-size: 10px;
  line-height: 12px;
  margin-left: 10px;
  border-radius: 10px;
  font-weight: bold;
  border: 1px solid #ffffff17;
  margin-top: 10px;
}

@media screen and (max-width: 991px) {
  .tabs-steps {
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #2d2f32;
    position: fixed;
    bottom: 60px;
    padding: 5px;
    left: 0;
    right: 0;
  }
  .tags {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .tags .tag {
    color: #aaaaabf2;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #505359;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .tabs-steps .tab {
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .tabs-steps .tab.active {
    background: var(--box-up);
  }

  .tabs-steps .tab p {
    color: var(--label-primary);
    font-size: 14px;
    line-height: 17px;
  }

  .tabs-steps .tab.active p {
    color: var(--label-secondary);
    font-weight: bold;
  }
  .progress-line,
  .progress-line > .done {
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    top: 0;
  }
  .progress-line {
    background: #99999938;
  }
  .done {
    background: var(--default);
  }
  .help {
    padding-bottom: 0;
    border-bottom: none;
  }
  .cost {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 5px 20px;
  }
}
</style>
