<template>
  <div>
    <base-card>
      <base-label text="Defina o(s) bairro(s)" />
      <address-input
        class="mb_0"
        placeholder="Procure por bairro"
        search
        @selectedAddress="onAddressSelected"
      />

      <div v-for="(address, index) in campaign.selectedAddresses" :key="index">
        <div class="address-box">
          <div class="address">
            <i class="fal fa-map-marker-alt" />
            <p>{{ formattedAddress(address) }}</p>
          </div>
          <base-button
            class="ml_10"
            design="four"
            @click="removeAddress(index)"
          >
            <i class="fal fa-times" />
          </base-button>
        </div>
      </div>
    </base-card>
  </div>
</template>
<script>
import AddressInput from '@/containers/AddressInput';
import Location from '@/plugins/core/schema/configurations/BasicInfo/Location';
import BaseButton from '@/components/Button';

const components = {
  AddressInput,
  BaseButton,
};
export default {
  components,
  data() {
    return {
      campaign: {
        selectedAddresses: [],
      },
      payload: { field: 'geographicDistrict' },
    };
  },
  methods: {
    onAddressSelected(option) {
      if (!option.address) {
        return;
      }
      const address = Location.fromObject(option.address);

      this.campaign.selectedAddresses.push(address);
    },
    formattedAddress(address) {
      if (this.payload.value === undefined) {
        this.payload.value = address;
        this.$emit('save', this.payload);
      }
      return `${address.address || ''} ${
        address.number ? `, ${address.number}` : ''
      } - ${address.district}, ${address.city} -
          ${address.state}, ${address.country} ${
        address.zipcode ? `- ${address.zipcode}` : ''
      }`;
    },
    removeAddress(index) {
      confirm('Deseja mesmo remover o endereço?', (res) => {
        if (res) {
          this.campaign.selectedAddresses = [];
          delete this.payload.value;
          this.$emit('save', this.payload);
          this.campaign.selectedAddresses.splice(index, 1);
        }
      });
    },
  },
};
</script>
<style scoped>
.address-box {
  display: flex;
  align-items: stretch;
  margin: 10px 0;
}
.address-box .address {
  background: var(--box-up);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #aaaaaaed;
}
.address-box .address p {
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
  font-weight: 300;
}
.address-box .address i {
  font-size: 15px;
  color: var(--icon-inactive);
}
</style>
