<template>
  <div>
    <base-card>
      <base-label text="Defina os dias do mês" />
      <div class="d-flex align-center">
        <base-input
          label="Dia do mês"
          class="w-100"
          v-model="dayInMonth"
          type="number"
          @keyup.enter="addDay(dayInMonth)"
          max="2"
        />
        <span class="apply" @click="() => addDay(dayInMonth)">Aplicar</span>
      </div>
      <div class="days">
        <div
          v-for="(day, dayIndex) in selectedDays"
          class="day"
          :key="dayIndex"
        >
          <p>{{ day }}</p>
          <span class="remove" @click="() => removeDay(day)">
            <i class="far fa-times"></i>
          </span>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';

export default {
  data() {
    return {
      dayInMonth: null,
      selectedDays: [],
    };
  },
  components: {
    BaseCard,
    BaseLabel,
    BaseInput,
  },
  watch: {
    dayInMonth(newValue) {
      if (newValue > 31) {
        this.dayInMonth = 31;
      }
    },
  },
  props: {
    campaign: {
      type: Object,
    },
  },
  created() {
    this.loadSelected();
    this.validate();
  },
  methods: {
    loadSelected() {
      if (
        this.campaign.configs &&
        this.campaign.configs.send_time &&
        this.campaign.configs.send_time.days_month
      ) {
        this.selectedDays = this.campaign.configs.send_time.days_month;
      }
    },
    validate() {
      if (this.selectedDays.length > 0) {
        this.$emit('validateDrawer', true, {
          type: 'DAYS_MONTH',
          values: {
            days_month: this.selectedDays,
          },
        });
      } else this.$emit('validateDrawer', false);
    },
    addDay(day) {
      if (!day) return;

      const foundIndex = this.selectedDays.indexOf(day);

      if (foundIndex === -1) {
        this.selectedDays.push(day);
        this.dayInMonth = null;
      }

      this.selectedDays.sort((a, b) => a - b);

      this.validate();
    },
    removeDay(day) {
      const foundIndex = this.selectedDays.indexOf(day);

      this.selectedDays.splice(foundIndex, 1);

      this.validate();
    },
  },
};
</script>
<style scoped>
.days {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  width: 320px;
  gap: 15px 0;
}
.days .day {
  background: rgba(46, 52, 56, 0.4);
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  padding-right: 15px;
  position: relative;
  margin-right: 30px;
}

.days .day:nth-child(1n + 2):not(:nth-child(3n + 1)):before {
  content: '';
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgba(111, 118, 126, 0.25);
  border-radius: 999;
  height: calc(100% - 10px);
  margin: auto;
}

.days .day p {
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.95);
}

.days .day .remove {
  font-size: 12px;
  margin-left: 25px;
  color: #6f767e;
  cursor: pointer;
}

.apply {
  font-size: 14px;
  color: var(--default);
  margin-left: 15px;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
}
.apply:hover {
  border-color: var(--default);
}
</style>
