<template>
  <div>
    <base-card>
      <base-label text="Defina a faixa de ticket médio" />
      <base-radio
        family="average-ticket"
        option="ticket-range"
        class="mt_15"
        @click="handleInputSelected('ticket-range')"
      >
        <p class="radio-label">Faixa de ticket médio</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'ticket-range'">
        <base-input
          placeholder="De"
          class="w-50 mb_0"
          v-model="range.min"
          type="tel"
          @change="mountPayload('range')"
          @keyup="mountPayload('range')"
          :money-mask="StringLanguage.currencyConfigs"
        />
        <base-input
          placeholder="Até"
          class="w-50 ml_15 mb_0"
          v-model="range.max"
          type="tel"
          @change="mountPayload('range')"
          @keyup="mountPayload('range')"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>

      <base-radio
        family="average-ticket"
        option="minimum-ticket"
        class="mt_15"
        @click="handleInputSelected('minimum-ticket')"
      >
        <p class="radio-label">Mínimo de ticket médio</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'minimum-ticket'">
        <base-input
          placeholder="A partir de"
          class="w-100 mb_0"
          v-model="exact"
          type="tel"
          @change="mountPayload('minimum')"
          @keyup="mountPayload('exact')"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>

      <base-radio
        family="average-ticket"
        option="exact-ticket"
        class="mt_15"
        @click="handleInputSelected('exact-ticket')"
      >
        <p class="radio-label">Valor exato de ticket médio</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'exact-ticket'">
        <base-input
          @change="mountPayload('exact')"
          @keyup="mountPayload('exact')"
          v-model="exact"
          type="tel"
          placeholder="Define o valor exato de ticket médio"
          class="w-100 mb_0"
          :money-mask="StringLanguage.currencyConfigs"
        />
      </div>
    </base-card>

    <base-card>
      <base-label text="Determine o tempo" />
      <base-radio
        family="average-ticket-time"
        option="all-ticket"
        class="mt_15"
        @click="handleInputSelected('')"
      >
        <p class="radio-label">Todo o período</p>
      </base-radio>
      <base-radio
        family="average-ticket-time"
        option="custom-ticket"
        class="mt_15"
        @click="handleInputSelected('custom-ticket')"
      >
        <p class="radio-label">Período personalizado</p>
      </base-radio>
    </base-card>

    <base-card v-if="controllerInputsDate === 'custom-ticket'">
      <base-label text="Determine o período" />
      <div class="custom-period">
        <p>Nos últimos</p>

        <div class="d-flex">
          <base-input
            placeholder="Inserir valor"
            class="w-50 mb_0"
            v-model="isLast"
            type="tel"
            @change="mountPayload('isLast')"
          />
          <base-select
            title="Selecione o tempo..."
            class="w-50 ml_15"
            design="three"
            v-model="type"
            @change="mountPayload('isLast')"
          >
            <option :value="'DAY'" selected>Dias</option>
            <option :value="'MONTH'">Meses</option>
            <option :value="'YEAR'">Anos</option>
          </base-select>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';
import BaseSelect from '@/components/Select';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
  BaseSelect,
};

export default {
  components,
  data() {
    return {
      number: 'range',
      exact: '',
      type: '',
      controllerInputs: '',
      controllerInputsDate: '',
      range: {},
      payload: {
        field: 'clientAverageTicket',
        value: { type: 'clientAverageTicket' },
      },
      isLast: '',
    };
  },
  methods: {
    handleInputSelected(value) {
      if (value === 'custom-ticket') {
        this.controllerInputsDate = 'custom-ticket';
      } else if (value === '') {
        this.controllerInputsDate = '';
      } else if (value !== 'custom-ticket') {
        this.controllerInputs = value;
      }
    },
    mountPayload(type) {
      const ACTION_TYPE = {
        DAY: 1,
        MONTH: 2,
        YEAR: 3,
      };
      const { payload } = this;

      if (type === 'range') {
        payload.value = this.range;
      }
      if (type === 'exact' || type === 'minimum') {
        payload.value[type] = this.exact;
      }
      if (this.isLast) {
        payload.value.isLast = this.isLast;
        payload.value.type = ACTION_TYPE[this.type];
      }
      this.$emit('save', payload);
    },
  },
  watch: {
    exact() {
      this.mountPayload('exact');
    },
    'range.max': function () {
      this.mountPayload('range');
    },
  },
};
</script>

<style scoped>
.custom-period {
  background: #2b3135;
  border-radius: 10px;
  padding: 15px 15px 5px;
  margin-top: 20px;
}
.custom-period p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
