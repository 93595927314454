<template>
  <base-card>
    <base-label text="Defina um intervalo de contemplação" />
    <base-input
      label="Intervalo de contemplação"
      v-model="newInterval"
      :money-mask="StringLanguage.timeConfigs.days"
      :thousandSeparator="false"
    />
  </base-card>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseCard from '@/components/Card';

const components = {
  BaseCard,
  BaseLabel,
  BaseInput,
};

export default {
  components,
  props: {
    interval: {
      type: Number,
    },
  },
  data() {
    return {
      newInterval: null,
    };
  },
  watch: {
    newInterval() {
      this.validate();
    },
  },
  mounted() {
    this.newInterval = this.interval;
    this.validate();
  },
  methods: {
    validate() {
      if (+this.newInterval >= 0) {
        this.$emit('validateDrawer', true, {
          type: 'SEVERAL_TIMES',
          values: {
            interval: +this.newInterval,
          },
        });
      } else this.$emit('validateDrawer', false);
    },
  },
};
</script>
