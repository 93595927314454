<template>
  <div>
    <base-card>
      <base-label text="Defina o valor mínimo e máximo da temperatura" />
      <div class="d-flex">
        <base-input
          class="w-100 mr_15"
          label="Temp. mín"
          v-model="temperature.min"
          :money-mask="StringLanguage.temperatureConfigs.celsius"
          :thousandSeparator="false"
        />
        <base-input
          class="w-100"
          label="Temp. máx"
          v-model="temperature.max"
          :money-mask="StringLanguage.temperatureConfigs.celsius"
          :thousandSeparator="false"
        />
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';

export default {
  components: {
    BaseCard,
    BaseLabel,
    BaseInput,
  },
  props: {
    climate: {
      type: Object,
    },
  },
  data() {
    return {
      temperature: {
        min: null,
        max: null,
      },
    };
  },
  watch: {
    temperature: {
      deep: true,
      handler() {
        this.validate();
      },
    },
  },
  mounted() {
    if (this.climate && this.climate.temperature) {
      this.temperature = this.climate.temperature;
    } else {
      this.temperature = {
        min: null,
        max: null,
      };
    }
    this.validate();
  },
  methods: {
    validate() {
      const min = +this.temperature.min;
      const max = +this.temperature.max;

      if (min > max) {
        this.$emit('validateDrawer', false);
      } else {
        this.$emit('validateDrawer', true, {
          type: 'temperature',
          values: {
            min,
            max,
          },
        });
      }
    },
  },
};
</script>
