<template>
  <div class="d-flex flex-column justify-between h-100">
    <base-input
      label="Nome da campanha"
      v-model="campaignName"
      class="pt_0 mb_0"
    />

    <div class="d-flex justify-end mt_30">
      <base-button
        text="Continuar"
        :disabled="!canSave()"
        @click="onSave()"
        class="big"
        :class="{ 'w-100': isMobile }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';

const components = {
  BaseInput,
  BaseButton,
};

export default {
  components,
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.campaignName = this.campaign.name;
  },
  data: () => ({ campaignName: '' }),
  methods: {
    canSave() {
      return this.campaignName && this.campaignName.length > 3;
    },
    onSave() {
      this.$emit('save', this.campaignName);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
