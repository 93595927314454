<template>
  <div>
    <base-card>
      <base-label
        text="Defina o valor mínimo e máximo da probabilidade de chuva"
      />
      <div class="d-flex">
        <base-input
          class="w-100 mr_15"
          label="Probabilidade mín"
          v-model="rain.min"
          :money-mask="StringLanguage.percentIntegerConfigs"
        />
        <base-input
          class="w-100"
          label="Probabilidade máx"
          v-model="rain.max"
          :money-mask="StringLanguage.percentIntegerConfigs"
        />
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';

export default {
  components: {
    BaseCard,
    BaseLabel,
    BaseInput,
  },
  props: {
    climate: {
      type: Object,
    },
  },
  data() {
    return {
      rain: {
        min: null,
        max: null,
      },
    };
  },
  watch: {
    rain: {
      deep: true,
      handler() {
        this.validate();
      },
    },
  },
  mounted() {
    if (this.climate && this.climate.rain) {
      this.rain = this.climate.rain;
    } else {
      this.rain = {
        min: null,
        max: null,
      };
    }
    this.validate();
  },
  methods: {
    validate() {
      const min = +this.rain.min;
      const max = +this.rain.max;
      if (max > min) {
        this.$emit('validateDrawer', true, {
          type: 'rain',
          values: {
            min,
            max,
          },
        });
      } else this.$emit('validateDrawer', false);
    },
  },
};
</script>
