<template>
  <div class="d-flex flex-column justify-between h-100">
    <div class="d-flex flex-column justify-between h-100">
      <div v-if="!showOptions" :key="triggerKey">
        <div>
          <p class="title">Tipo do gatilho</p>
          <div class="options">
            <div
              class="option"
              :class="{
                active: payload.configs.trigger.type === 'RE',
              }"
              @click="setTriggerRecurrent()"
            >
              <base-radio
                family="trigger"
                option="RE"
                v-model="payload.configs.trigger.type"
                @click.prevent
              />
              <i class="fal fa-repeat icon" />
              <p class="name">Recorrente</p>
            </div>

            <div
              class="option"
              :class="{ active: payload.configs.trigger.type === 'PN' }"
              @click="openDrawer()"
            >
              <base-radio
                family="trigger"
                option="PN"
                v-model="payload.configs.trigger.type"
                @click.prevent
              />
              <i class="fal fa-circle-1 icon" />
              <p class="name hover">Pontual</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showOptions">
        <p class="trigger-type" @click="backMainTriggerSelect()">
          <i class="far fa-chevron-left" />
          Disparo recorrente
        </p>
        <firing :campaign="campaign" @onSaveFiring="setFiringPayload" />
        <climate
          :campaign="campaign"
          @onSaveClimate="setClimatePayload"
          @onSaveWeather="setWeatherPayload"
        />
      </div>

      <div class="d-flex justify-end mt_30">
        <base-button
          v-if="!showOptions"
          text="Continuar"
          @click="checkTriggerType()"
          class="big"
          :class="{ 'w-100': isMobile }"
          :disabled="disabled"
        />

        <base-button
          v-if="showOptions"
          text="Continuar"
          @click="saveTrigger()"
          class="big"
          :class="{ 'w-100': isMobile }"
          :disabled="disabled"
        />
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      :title="drawer.name"
      @onClose="closeDrawer()"
    >
      <base-card>
        <base-label text="Defina o tipo do disparo pontual" />
        <base-radio
          family="triggerPointShot"
          option="scheduled"
          class="mb_15"
          v-model="triggerPointShot"
        >
          Disparo agendado
        </base-radio>
        <base-radio
          family="triggerPointShot"
          option="immediate"
          v-model="triggerPointShot"
        >
          Disparo imediato
        </base-radio>

        <div class="scheduled" v-if="triggerPointShot === 'scheduled'">
          <p>Insira a data do agendamento</p>
          <base-input type="date" v-model="scheduled.date" class="w-50" />

          <div class="line"></div>

          <p>Defina a faixa de horário</p>
          <base-select :items="timeIntervalOptions" v-model="scheduled.time" />
        </div>
      </base-card>

      <template slot="footer">
        <base-button
          text="Aplicar"
          class="w-100"
          @click="applyConfig"
          :disabled="!valid"
        />
      </template>
    </base-drawer>
  </div>
</template>

<script>
import Ajv from 'ajv';
import { mapState } from 'vuex';
import Firing from '@/containers/Performance/Trigger/Firing';
import Climate from '@/containers/Performance/Trigger/Climate';
import BaseButton from '@/components/Button';
import BaseRadio from '@/components/Radio';
import BaseDrawer from '@/components/Drawer';
import BaseSelect from '@/components/Select';
import BaseCheckbox from '@/components/Checkbox';
import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';

const components = {
  Firing,
  Climate,
  BaseDrawer,
  BaseButton,
  BaseRadio,
  BaseSelect,
  BaseCheckbox,
  BaseCard,
  BaseLabel,
  BaseInput,
};

export default {
  components,
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.payload.configs = JSON.parse(JSON.stringify(this.campaign.configs));
    this.payload.configs.trigger = { type: this.campaign.type };
    this.disabled = !this.payload.configs.trigger.type;
  },
  data() {
    return {
      payload: { configs: { send_time: {} } },
      drawer: { open: false, close: false },
      showOptions: false,
      valid: false,
      disabled: false,
      triggerPointShot: null,
      timeIntervalOptions: this.getTimeIntervalOptions(),
      scheduled: {
        date: null,
        time: null,
      },
      selectedTrigger: false,
      triggerKey: `trigger-${Date.now()}`,
    };
  },
  methods: {
    backMainTriggerSelect() {
      this.showOptions = !this.showOptions;
      this.disabled = false;
    },
    validateRESchema() {
      const schema = {
        type: 'object',
        properties: {
          trigger: {
            type: 'object',
            properties: {
              type: { type: 'string', enum: ['RE'] },
            },
          },
          send_time: {
            type: ['object'],
            properties: {
              all_days: { type: 'boolean' },
              days_week: { type: 'array' },
              days_month: { type: 'array' },
            },
            anyOf: [
              { required: ['all_days'] },
              { required: ['days_week'] },
              { required: ['days_month'] },
            ],
          },
        },
        required: ['trigger', 'send_time'],
        additionalProperties: true,
      };
      const ajv = new Ajv();
      const validate = ajv.compile(schema);
      const valid = validate(this.payload.configs);
      this.disabled = !valid;
    },
    saveTrigger() {
      const configs = JSON.parse(JSON.stringify(this.payload.configs));
      delete configs.trigger;

      this.$emit('onSaveTrigger', {
        type: this.payload.configs.trigger.type,
        configs,
      });
    },
    checkTriggerType() {
      if (this.payload.configs.trigger.type === 'RE') {
        if (!this.payload.configs.send_time) {
          this.payload.configs.send_time = {};
        }
        this.showOptions = true;
        this.validateRESchema();
      }

      if (this.payload.configs.trigger.type === 'PN') {
        this.saveTrigger();
      }
    },
    setTriggerRecurrent() {
      this.payload.configs.trigger.type = 'RE';
      this.selectedTrigger = !this.selectedTrigger;
      this.triggerKey = `trigger-${Date.now()}`;
    },
    setPayload(object) {
      this.payload.configs = { ...this.payload.configs, ...object };
      this.payload.configs.send_time = {
        ...this.payload.configs.send_time,
        ...object.firing,
      };
    },

    setFiringPayload(payload) {
      this.payload.configs.send_time = payload;
      this.validateRESchema();
    },

    setClimatePayload(payload) {
      this.payload.configs.climate = payload;
    },

    setWeatherPayload(payload) {
      this.payload.configs.weather = payload;
    },

    applyConfig() {
      this.drawer.close = !this.drawer.close;
      this.payload.configs.trigger.type = 'PN';
      this.payload.configs.send_time =
        this.triggerPointShot === 'scheduled' ? this.scheduled : null;
      this.selectedTrigger = !this.selectedTrigger;
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;

      this.drawer.name = 'Pontual';
    },
    closeDrawer() {
      this.drawer.close = !this.drawer.close;
    },
    nextStep(step) {
      this.$emit('goToStep', step);
    },
    getTimeIntervalOptions() {
      const horaries = [];

      for (let i = 0; i <= 22; i++) {
        horaries.push({
          value: i < 10 ? `0${i}:00` : `${i}:00`,
          text: `${String(i).padStart(2, '0')}h - ${String(i + 1).padStart(
            2,
            '0'
          )}h`,
        });
      }

      return horaries;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    triggerPointShot: {
      handler(newValue) {
        if (newValue === 'immediate') {
          this.valid = true;
        }

        if (newValue === 'scheduled') {
          this.valid = false;
        }
      },
    },
    scheduled: {
      deep: true,
      immediate: true,
      handler() {
        this.valid = this.scheduled.date && this.scheduled.time;
      },
    },
  },
};
</script>

<style scoped>
.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

@media screen and (max-width: 991px) {
  .options {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bebebf;
  margin-bottom: 20px;
}

.option {
  background: rgba(46, 52, 56, 0.35);
  border-radius: 10px;
  height: 76px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid transparent;
}

.option.active {
  border: 1px solid rgba(97, 97, 255, 0.55);
}

.option.active .icon {
  background: #313550;
  color: var(--default);
}

.option .icon {
  background: rgba(54, 62, 66, 0.3);
  border-radius: 10px;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-inactive);
  font-size: 15px;
}

.option .name {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 170, 0.8);
}

.option .name.hover {
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
  margin-top: 2px;
}

.option .name.hover:hover {
  color: rgb(170 170 170);
  border-bottom: 1px solid var(--label-primary);
  cursor: pointer;
}

.week-day {
  font-size: 14px;
  width: 29px;
  height: 29px;
  color: var(--label-primary);
  border: 1px solid #6f767e26;
  border-radius: 10px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.week-day.selected {
  color: white;
  border-color: var(--default);
  background: var(--default);
}

.trigger-type {
  padding-bottom: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}
.trigger-type i {
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  margin-right: 15px;
}

.line {
  height: 1px;
  background: rgba(111, 118, 126, 0.12);
  margin-bottom: 15px;
}

.scheduled {
  background: rgba(46, 52, 56, 0.55);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;
  margin-top: 15px;
}

.scheduled p {
  font-size: 14px;
  line-height: 17px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 10px;
}
</style>
