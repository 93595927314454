<template>
  <div>
    <base-card>
      <base-label text="Defina os dias da semana" />

      <div class="d-flex">
        <div
          v-for="day in daysWeek"
          :key="day.id"
          class="week-day"
          :title="day.title"
          :class="{ selected: selectedDays.includes(day.id) }"
          @click="changeWeekDay(day.id)"
        >
          {{ day.name }}
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseLabel from '@/components/Label';

export default {
  data() {
    return {
      daysWeek: [
        {
          id: 0,
          name: 'S',
          title: 'Segunda-feira',
        },
        {
          id: 1,
          name: 'T',
          title: 'Terça-feira',
        },
        {
          id: 2,
          name: 'Q',
          title: 'Quarta-feira',
        },
        {
          id: 3,
          name: 'Q',
          title: 'Quinta-feira',
        },
        {
          id: 4,
          name: 'S',
          title: 'Sexta-feira',
        },
        {
          id: 5,
          name: 'S',
          title: 'Sábado',
        },
        {
          id: 6,
          name: 'D',
          title: 'Domingo',
        },
      ],
      selectedDays: [],
    };
  },
  components: {
    BaseCard,
    BaseLabel,
  },
  props: {
    campaign: {
      type: Object,
    },
  },
  created() {
    this.loadSelected();
    this.validate();
  },
  methods: {
    loadSelected() {
      if (
        this.campaign.configs &&
        this.campaign.configs.send_time &&
        this.campaign.configs.send_time.days_week
      ) {
        this.selectedDays = this.campaign.configs.send_time.days_week;
      }
    },
    validate() {
      if (this.selectedDays.length > 0) {
        this.$emit('validateDrawer', true, {
          type: 'DAYS_WEEK',
          values: {
            days_week: this.selectedDays,
          },
        });
      } else this.$emit('validateDrawer', false);
    },
    changeWeekDay(weekDay) {
      const foundIndex = this.selectedDays.indexOf(this.daysWeek[weekDay].id);

      if (foundIndex === -1) {
        this.selectedDays.push(weekDay);
      } else {
        this.selectedDays.splice(foundIndex, 1);
      }

      this.validate();
    },
  },
};
</script>
<style scoped>
.week-day {
  font-size: 14px;
  width: 29px;
  height: 29px;
  color: var(--label-primary);
  border: 1px solid #6f767e26;
  border-radius: 10px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.week-day.selected {
  color: white;
  border-color: var(--default);
  background: var(--default);
}
</style>
