<template>
  <div class="d-flex flex-column justify-between h-100">
    <div>
      <base-radio
        family="consideration"
        option="allHistory"
        v-model="analysisOption"
        @click="setTypeShot('allHistory')"
      >
        <p class="radio-label">Considerar toda a história</p>
      </base-radio>

      <base-radio
        family="consideration"
        option="lastMonths"
        class="mt_15"
        v-model="analysisOption"
      >
        <p class="radio-label">Considerar usuários mais recentes</p>
      </base-radio>

      <div v-if="analysisOption === 'lastMonths'" class="month-box">
        <base-label text="Deve ter feito ao menos um pedido nos últimos" />
        <base-input
          design="three"
          label="Período de meses"
          class="w-100 mb_0"
          v-model="lastMonths"
          @keyup="setTypeShot('lastMonths')"
          :money-mask="StringLanguage.timeConfigs.months"
        />
      </div>
    </div>

    <div
      class="d-flex mt_30 justify-end"
      :class="{ 'flex-column-reverse': isMobile }"
    >
      <base-button
        design="three"
        text="Não realizar disparo inicial e pular análise"
        @click="setTypeShot('noAnalisys')"
        :class="isMobile ? 'w-100 mt_15' : 'mr_15 big'"
      />

      <base-button
        text="Continuar"
        @click="save()"
        class="big"
        :class="{ 'w-100': isMobile }"
        :disabled="
          analysisOption === 'lastMonths' && (!lastMonths || lastMonths <= 0)
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseLabel from '@/components/Label';
import BaseInput from '@/components/Input';
import BaseRadio from '@/components/Radio';
import BaseButton from '@/components/Button';

const components = {
  BaseLabel,
  BaseInput,
  BaseRadio,
  BaseButton,
};

export default {
  components,
  props: {
    campaign: { type: Object },
  },
  data() {
    return {
      objItemPayload: {},
      type: {
        noAnalisys: {
          id: 1,
          type: 'noAnalisys',
        },
        allHistory: {
          id: 4,
          type: 'allHistory',
        },
        lastMonths: {
          id: 2,
          type: 'lastMonths',
          createdAtMonthsBefore: this.lastMonths,
        },
      },
      typeShot: 0,
      analysisOption: null,
      lastMonths: null,
    };
  },
  created() {
    this.lastMonths = this.campaign.configs.createdAtMonthsBefore;
    this.analysisOption = this.selectType(this.campaign.initial_flag);
    this.setTypeShot(this.selectType(this.campaign.initial_flag));
  },
  methods: {
    selectType(value) {
      switch (value) {
        case 1:
          return 'noAnalisys';
        case 2:
          return 'lastMonths';
        case 4:
          return 'allHistory';
      }
    },
    setTypeShot(value) {
      const objType = {};
      if (value === this.type.allHistory.type) {
        objType.initial_flag = this.type.allHistory.id;
      } else if (value === this.type.noAnalisys.type) {
        objType.initial_flag = this.type.noAnalisys.id;
        this.objItemPayload = objType;
        this.save();
        return;
      } else if (value === this.type.lastMonths.type) {
        objType.initial_flag = this.type.lastMonths.id;
        objType.createdAtMonthsBefore = this.lastMonths;
      }

      this.objItemPayload = objType;
    },
    nextStep(step) {
      this.$emit('goToStep', step, this.campaign);
    },
    save() {
      const { createdAtMonthsBefore, initial_flag } = this.objItemPayload;
      const obj = {
        ...this.campaign,
        ...{ initial_flag, configs: { createdAtMonthsBefore } },
      };
      this.$emit('save', obj);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.month-box {
  background-color: #2a3034;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
</style>
