<template>
  <div
    class="tab-content"
    v-show="tabStep === (isMobile ? 'tab-content' : 'steps')"
  >
    <div class="progress-line" v-if="isMobile">
      <div class="done" :style="`width: ${16.66 * activeTab}%`"></div>
    </div>

    <div class="header-desktop" v-if="!isMobile && activeTab">
      <p class="title">{{ getStepHeaderDesktop() }}</p>
    </div>

    <div class="header-mobile" v-if="isMobile && activeTab">
      <p>{{ activeTab }} de 5 - {{ getStepHeaderMobile() }}</p>
    </div>
    <div
      class="h-100"
      v-if="activeTab === STEPS.NAME_STEP"
      :key="`nameStep-${Date.now()}`"
    >
      <name-step :campaign="campaign" @save="(value) => setName(value, true)" />
    </div>
    <div
      class="h-100"
      v-if="activeTab === STEPS.TRIGGER_STEP"
      :key="`triggerStep-${Date.now()}`"
    >
      <trigger-step
        :campaign="campaign"
        @goToStep="goToStep"
        @onSaveTrigger="(value) => setTriggers(value.type, value.configs)"
      />
    </div>
    <div
      class="h-100"
      v-if="activeTab === STEPS.FIRING_STEP"
      :key="`firingStep-${Date.now()}`"
    >
      <firing-step
        @onSaveFiring="(value) => setFiring(value)"
        :campaign="campaign"
        @goToStep="goToStep"
      />
    </div>
    <div
      v-if="activeTab === STEPS.DELIVERY_STEP"
      :key="`deliveryStep-${Date.now()}`"
    ></div>
    <delivery-step
      class="h-100"
      v-show="activeTab === STEPS.DELIVERY_STEP"
      :loading="loading"
      :campaign="campaign"
      @goToStep="goToStep"
    />
    <div
      class="h-100"
      v-if="activeTab === STEPS.SEGMENTATION_STEP"
      :key="`segmentationStep-${Date.now()}`"
    >
      <segmentation-step
        :audience="{
          name: 'campaings',
          visible: true,
          rules: [],
        }"
        :campaign="campaign"
        @goToStep="goToStep"
      />
    </div>
    <div
      class="h-100"
      v-if="activeTab === STEPS.ANALYSIS_STEP"
      :key="`analysisStep-${Date.now()}`"
    >
      <analysis-step
        v-show="activeTab === STEPS.ANALYSIS_STEP"
        :loading="loading"
        :campaign="campaign"
        @goToStep="goToStep"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NameStep from '@/containers/Performance/SetpsValidator/NameValidator.vue';
import TriggerStep from '@/containers/Performance/SetpsValidator/TriggerStep.vue';
import FiringStep from '@/containers/Performance/SetpsValidator/FiringStep.vue';
import SegmentationStep from '@/containers/Performance/SetpsValidator/SegmentationStep';
import DeliveryStep from '@/containers/Performance/SetpsValidator/DeliveryStep.vue';
import AnalysisStep from '@/containers/Performance/SetpsValidator/AnalysisStep';
import BaseButton from '@/components/Button';

const STEPS = {
  NAME_STEP: 1,
  TRIGGER_STEP: 2,
  FIRING_STEP: 3,
  DELIVERY_STEP: 4,
  SEGMENTATION_STEP: 5,
  ANALYSIS_STEP: 6,
};

const components = {
  NameStep,
  TriggerStep,
  FiringStep,
  SegmentationStep,
  DeliveryStep,
  AnalysisStep,
  BaseButton,
};

export default {
  components,
  props: {
    loading: { type: Boolean, default: false },
    campaign: { type: Object },
    tabStep: { type: String, default: 'steps' },
    activeTab: { type: Number, default: 0 },
  },
  data() {
    return {
      tabs: {
        NAME_STEP: {
          alias: 'NAME_STEP',
          index: 1,
          description: 'Nome da campanha',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        TRIGGER_STEP: {
          alias: 'TRIGGER_STEP',
          index: 2,
          description: 'Gatilho',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        FIRING_STEP: {
          alias: 'FIRING_STEP',
          index: 3,
          description: 'Disparo',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        DELIVERY_STEP: {
          alias: 'DELIVERY_STEP',
          index: 4,
          description: 'Método de envio',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
        SEGMENTATION_STEP: {
          alias: 'SEGMENTATION_STEP',
          index: 5,
          description: 'Segmentação',
          subdescription: null,
          subdescriptionType: 'tags',
          enabled: true,
        },
        ANALYSIS_STEP: {
          alias: 'ANALYSIS_STEP',
          index: 6,
          description: 'Análise',
          subdescription: null,
          subdescriptionType: 'text',
          enabled: true,
        },
      },
      STEPS,
      campaigns: {},
    };
  },

  methods: {
    saveAudience(data) {
      const { name, visible, rules } = data;
      const audience = {
        audience_name: name,
        visible,
        rules,
      };
      this.goToStep(4, audience);
    },
    setTriggers(type, configs) {
      this.campaigns.type = type;
      this.campaigns.configs = {
        ...this.campaigns.configs,
        ...configs,
      };

      if (this.campaigns.type === 'PN') {
        this.goToStep(STEPS.DELIVERY_STEP, this.campaigns);
        this.tabs.TRIGGER_STEP.enabled = true;
        this.tabs.FIRING_STEP.enabled = false;
      } else {
        this.goToStep(STEPS.FIRING_STEP, this.campaigns);
        this.tabs.TRIGGER_STEP.enabled = true;
        this.tabs.FIRING_STEP.enabled = true;
      }
    },
    setFiring(configs) {
      const interval = configs.interval != null ? configs.interval : null;

      this.campaigns.configs = {
        ...(this.campaign.configs || {}),
        interval,
        single_conversion: configs.single_conversion || false,
        send_time: {
          ...(this.campaign.configs && this.campaign.configs.send_time
            ? this.campaign.configs.send_time
            : {}),
          ...configs.send_time,
        },
      };

      this.tabs.FIRING_STEP.enabled = true;
      this.tabs.DELIVERY_STEP.enabled = true;

      this.goToStep(STEPS.DELIVERY_STEP, this.campaigns);
    },
    setName(name, jumpToNext) {
      this.campaigns.name = name;
      this.tabs.NAME_STEP.enabled = true;
      this.tabs.TRIGGER_STEP.enabled = true;
      if (jumpToNext) {
        this.goToStep(STEPS.TRIGGER_STEP, this.campaigns);
      }
    },
    getStepHeaderDesktop() {
      switch (this.activeTab) {
        case STEPS.NAME_STEP:
          return 'Nome da campanha';
        case STEPS.TRIGGER_STEP:
          return 'Gatilho';
        case STEPS.FIRING_STEP:
          return 'Disparo';
        case STEPS.DELIVERY_STEP:
          return 'Método de envio';
        case STEPS.SEGMENTATION_STEP:
          return 'Segmentação';
        case STEPS.ANALYSIS_STEP:
          return 'Análise';
      }
    },
    getStepHeaderMobile() {
      switch (this.activeTab) {
        case STEPS.NAME_STEP:
          return 'Nome da campanha';
        case STEPS.TRIGGER_STEP:
          return 'Gatilho';
        case STEPS.FIRING_STEP:
          return 'Disparo';
        case STEPS.DELIVERY_STEP:
          return 'Método de envio';
        case STEPS.SEGMENTATION_STEP:
          return 'Segmentação';
        case STEPS.ANALYSIS_STEP:
          return 'Análise';
      }
    },
    goToStep(step, data) {
      this.campaigns = data;
      this.$emit('goToStep', step, data);
    },
    save(data) {
      this.campaigns = { ...this.campaigns, ...data };
      this.$emit('save', this.campaigns);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.header-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  padding-bottom: 20px;
  margin-bottom: 25px;
  position: relative;
}
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #bebebf;
}
.place-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  flex: 1;
  align-items: flex-end;
}

.place-bottom button {
  padding: 15px 40px;
}

.place-bottom button + button {
  margin-left: 20px;
}

.header-mobile {
  color: rgba(170, 170, 171, 0.9);
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: bold;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 991px) {
  .progress-line,
  .progress-line > .done {
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    top: 0;
  }
  .progress-line {
    border: 1px solid rgba(111, 118, 126, 0.1);
  }
  .progress-line > .done {
    background: var(--default);
  }
  .place-bottom {
    flex-direction: column-reverse;
  }
  .place-bottom button {
    margin-top: 15px;
    width: 100%;
  }
}
</style>
