<template>
  <div class="d-flex flex-column justify-between h-100">
    <div class="grid-options">
      <base-radio
        family="send-method"
        design="two"
        :option="'sms'"
        v-model="type_send"
        :checked="type_send == 'sms'"
      >
        <p class="radio-label">SMS</p>
      </base-radio>
      <base-radio
        family="send-method"
        design="two"
        :option="'email'"
        v-model="type_send"
        :checked="type_send == 'email'"
        :enabled="false"
      >
        <!-- <p class="radio-label">Email</p> -->
        <!-- <base-badge -->
        <!--   class="badge-custom" -->
        <!--   name="Necessita de configuração" -->
        <!--   type="orange" -->
        <!-- /> -->
        <div class="d-flex align-center">
          <p class="radio-label disabled">Email</p>
          <base-badge class="badge-custom" name="Em breve" type="exclamation" />
        </div>
      </base-radio>
      <base-radio
        family="send-method"
        design="two"
        :option="'whatsapp'"
        v-model="type_send"
        :checked="type_send === 'whatsapp'"
        :enabled="false"
      >
        <div class="d-flex align-center">
          <p class="radio-label disabled">WhatsApp</p>
          <base-badge class="badge-custom" name="Em breve" type="exclamation" />
        </div>
      </base-radio>
      <base-radio
        family="send-method"
        design="two"
        :option="'fallback'"
        v-model="type_send"
        :checked="type_send === 'fallback'"
        :enabled="false"
      >
        <div class="d-flex align-center">
          <p class="radio-label disabled">Fallback</p>
          <base-badge class="badge-custom" name="Em breve" type="exclamation" />
        </div>
      </base-radio>
    </div>

    <div class="d-flex justify-end mt_30">
      <base-button
        text="Continuar"
        @click="nextStep(5)"
        class="big"
        :class="{ 'w-100': isMobile }"
        :disabled="!type_send"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseButton from '@/components/Button';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';
import BaseBadge from '@/components/Badge';

const components = {
  BaseButton,
  BaseLabel,
  BaseRadio,
  BaseBadge,
};

export default {
  components,
  props: {
    loading: { type: Boolean, default: false },
    campaign: {
      type: Object,
    },
  },
  data() {
    return {
      type_send:
        (Object.keys(this.campaign) || []).length > 3
          ? this.campaign.type_send
          : '',
    };
  },
  methods: {
    checkValid() {
      const { id } = this.$route.params;
      if (+id > 0) {
        this.type_send = this.campaign.type_send;
      }
    },
    nextStep(step) {
      this.$emit('goToStep', step, { type_send: this.type_send });
    },
  },
  created() {
    this.checkValid();
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media screen and (max-width: 1320px) {
  .grid-options {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
  }
}

@media screen and (max-width: 991px) {
  .grid-options {
    grid-template-columns: repeat(1, 1fr);
  }
}

.badge-custom {
  top: 0;
  bottom: 0;
  height: 25px;
  margin: auto;
}
</style>
