<template>
  <div>
    <base-card>
      <base-label text="Defina a quantidade de conversões" />
      <base-radio
        family="average-order"
        option="order-range"
        class="mt_15"
        @click="handleInputSelected('order-range')"
      >
        <p class="radio-label">Faixa de quantidade de conversões</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'order-range'">
        <base-input
          placeholder="De"
          class="w-50 mb_0"
          v-model="range.min"
          @change="mountPayload('range')"
          :thousandSeparator="false"
          :moneyMask="StringLanguage.general.conversions"
        />
        <base-input
          placeholder="Até"
          class="w-50 ml_15 mb_0"
          v-model="range.max"
          @change="mountPayload('range')"
          :thousandSeparator="false"
          :moneyMask="StringLanguage.general.conversions"
        />
      </div>

      <base-radio
        family="average-order"
        option="minimum-order"
        class="mt_15"
        @click="handleInputSelected('minimum-order')"
      >
        <p class="radio-label">Quantidade mínima de conversões</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'minimum-order'">
        <base-input
          placeholder="A partir de"
          class="w-100 mb_0"
          v-model="exact"
          @change="mountPayload('minimum')"
          :thousandSeparator="false"
          :moneyMask="StringLanguage.general.conversions"
        />
      </div>

      <base-radio
        family="average-order"
        option="exact-order"
        class="mt_15"
        @click="handleInputSelected('exact-order')"
      >
        <p class="radio-label">Quantidade de conversões exata</p>
      </base-radio>
      <div class="d-flex mt_10" v-if="controllerInputs === 'exact-order'">
        <base-input
          @change="mountPayload('exact')"
          v-model="exact"
          placeholder="Define o valor exato de order médio"
          class="w-100 mb_0"
          :thousandSeparator="false"
          :moneyMask="StringLanguage.general.conversions"
        />
      </div>
    </base-card>

    <base-card>
      <base-label text="Determine o tempo" />
      <base-radio
        family="average-order-time"
        option="all-order"
        class="mt_15"
        @click="handleInputSelected('')"
      >
        <p class="radio-label">Todo o período</p>
      </base-radio>
      <base-radio
        family="average-order-time"
        option="custom-order"
        class="mt_15"
        @click="handleInputSelected('custom-order')"
      >
        <p class="radio-label">Período personalizado</p>
      </base-radio>
    </base-card>

    <base-card v-if="controllerInputsDate === 'custom-order'">
      <base-label text="Determine o período" />
      <div class="custom-period">
        <p>Nos últimos</p>

        <div class="d-flex">
          <base-input
            placeholder="Inserir valor"
            class="w-50 mb_0"
            v-model="isLast"
            type="number"
            @change="mountPayload('isLast')"
          />
          <base-select
            title="Selecione o tempo..."
            class="w-50 ml_15"
            design="three"
            v-model="type"
            @change="mountPayload('isLast')"
          >
            <option :value="'DAY'" selected>Dias</option>
            <option :value="'MONTH'">Meses</option>
            <option :value="'YEAR'">Anos</option>
          </base-select>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';
import BaseSelect from '@/components/Select';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
  BaseSelect,
};

export default {
  components,
  data() {
    return {
      number: 'range',
      exact: '',
      type: '',
      controllerInputs: '',
      controllerInputsDate: '',
      range: {},
      isLast: '',
      payload: {
        field: 'clientOrderQuantity',
        value: { type: 'clientOrderQuantity' },
      },
    };
  },
  methods: {
    handleInputSelected(value) {
      if (value === 'custom-order') {
        this.controllerInputsDate = 'custom-order';
      } else if (value === '') {
        this.controllerInputsDate = '';
      } else if (value !== 'custom-order') {
        this.controllerInputs = value;
      }
    },
    mountPayload(type) {
      const ACTION_TYPE = {
        DAY: 1,
        MONTH: 2,
        YEAR: 3,
      };
      const { payload } = this;

      if (type === 'range') {
        payload.value = this.range;
      }
      if (type === 'exact' || type === 'minimum') {
        payload.value[type] = this.exact;
      }
      if (this.isLast) {
        payload.value.isLast = this.isLast;
        payload.value.type = ACTION_TYPE[this.type];
      }
      this.$emit('save', payload);
    },
  },
  watch: {
    exact() {
      this.mountPayload('exact');
    },
    'range.max': function () {
      this.mountPayload('range');
    },
  },
};
</script>

<style scoped>
.custom-period {
  background: #2b3135;
  border-radius: 10px;
  padding: 15px 15px 5px;
  margin-top: 20px;
}
.custom-period p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
