<template>
  <div>
    <base-card>
      <base-label text="Determine o tempo" />
      <base-radio
        family="days-without-order-time"
        option="all-days"
        class="mt_15"
        @click="handleInputSelected('')"
      >
        <p class="radio-label">Todo o período</p>
      </base-radio>
      <base-radio
        family="days-without-order-time"
        option="custom-days"
        class="mt_15"
        @click="handleInputSelected('custom-period')"
      >
        <p class="radio-label">Período personalizado</p>
      </base-radio>
    </base-card>

    <base-card v-if="controllerInputs === 'custom-period'">
      <base-label text="Determine o período" />
      <div class="custom-period">
        <p>Nos últimos</p>

        <div class="d-flex">
          <base-input
            placeholder="Inserir valor"
            class="w-50 mb_0"
            v-model="exact"
            type="number"
            @change="mountPayload('isLast')"
          />
          <base-select
            title="Selecione o tempo..."
            class="w-50 ml_15"
            design="three"
            v-model="type"
            @change="mountPayload('isLast')"
          >
            <option :value="'DAY'" selected>Dias</option>
            <option :value="'MONTH'">Meses</option>
            <option :value="'YEAR'">Anos</option>
          </base-select>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';
import BaseSelect from '@/components/Select';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
  BaseSelect,
};

export default {
  components,
  data() {
    return {
      number: 'range',
      exact: '',
      type: '',
      controllerInputs: '',
    };
  },
  methods: {
    handleInputSelected(value) {
      this.controllerInputs = value;
      this.exact = '';
      this.range = {};
    },
    mountPayload(type) {
      const ACTION_TYPE = {
        DAY: 1,
        MONTH: 2,
        YEAR: 3,
      };

      const payload = {
        field: 'clientRegisterTimer',
        value: { type: 'clientRegisterTimer' },
      };

      if (type === 'isLast') {
        payload.value = { isLast: this.exact, type: ACTION_TYPE[this.type] };
      }

      this.$emit('save', payload);
    },
  },
  watch: {
    exact() {
      this.mountPayload('exact');
    },
    'range.max': function () {
      this.mountPayload('range');
    },
  },
};
</script>

<style scoped>
.custom-period {
  background: #2b3135;
  border-radius: 10px;
  padding: 15px 15px 5px;
  margin-top: 20px;
}
.custom-period p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
