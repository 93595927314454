<template>
  <div class="h-100">
    <div class="d-flex flex-column justify-between h-100">
      <div>
        <div v-if="!createCustomSegmentation">
          <p
            class="segmentation-type"
            v-if="
              (segmentationType === 'new-segmentation' &&
                stepPublic === 'new-public') ||
              stepPublic === 'public-name'
            "
            @click="backStep"
          >
            <i class="far fa-chevron-left" />
            {{ stepPublic == 'public-name' ? 'Cancelar' : 'Nova segmentação' }}
          </p>
          <p
            class="segmentation-type"
            v-if="
              segmentationType === 'custom-public' &&
              stepPublic === 'custom-public'
            "
            @click="
              (saveAndCreatePublic = false), (stepPublic = 'public-options')
            "
          >
            <i class="far fa-chevron-left" />
            Usar público personalizado
          </p>
        </div>

        <div
          v-if="!createCustomSegmentation && stepPublic === 'public-options'"
        >
          <div class="grid-options">
            <base-radio
              family="segmentation"
              v-model="segmentationType"
              design="two"
              :option="'new-segmentation'"
              @click="() => (activeNextPass = true)"
            >
              <p class="radio-label">Nova segmentação</p>
            </base-radio>
            <base-radio
              family="segmentation"
              v-model="segmentationType"
              design="two"
              :option="'custom-public'"
              @click="() => ((activePersonal = false), (activeNextPass = true))"
              :enabled="hasAudiences"
            >
              <div class="d-flex align-center">
                <p class="radio-label" :class="{ disabled: !hasAudiences }">
                  Usar público personalizado
                </p>
              </div>
            </base-radio>
            <base-radio
              family="segmentation"
              v-model="segmentationType"
              design="two"
              :option="'suggested-public'"
              :enabled="false"
            >
              <div class="d-flex align-center">
                <p class="radio-label disabled">Usar público sugerido</p>
              </div>
            </base-radio>
          </div>
        </div>

        <div v-if="stepPublic === 'new-public' || createCustomSegmentation">
          <div class="collapse">
            <new-segmenter
              @segmenterRules="updateRules"
              :audience="audienceConfigs"
              :campaign="campaign"
            />
          </div>
        </div>

        <div
          class="publics"
          v-if="stepPublic === 'custom-public' && !createCustomSegmentation"
        >
          <div class="publics-scroll">
            <!-- listagem aqui -->
            <div class="public mb_10" v-for="item in audiences" :key="item.id">
              <div class="content">
                <div class="header-public">
                  <base-radio
                    family="custom-public"
                    :option="item.id"
                    @click="selectAudience(item)"
                  />
                  <i class="fal fa-user-group" />
                  <p class="name">{{ item.name }}</p>
                </div>

                <div class="tags-public">
                  <div
                    class="tag"
                    v-for="rules in item.rules"
                    :key="`${rules.type}-${Date.now()}`"
                  >
                    {{ rulesTypes[rules.type] }}
                  </div>
                </div>
              </div>
            </div>

            <infinite-loading
              class="publics-infinite-loader"
              v-if="stepPublic === 'custom-public' && !createCustomSegmentation"
              @infinite="loadAudiences"
            >
              <div slot="no-more"></div>
              <span slot="spinner">
                <base-loading />
              </span>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </div>

        <div v-if="stepPublic === 'public-name'">
          <base-label>Nome do público</base-label>

          <base-input v-model="namePublic" />
        </div>
      </div>

      <div
        class="d-flex mt_30 justify-end relative"
        :class="{ 'flex-column-reverse align-center': isMobile }"
      >
        <base-checkbox
          v-if="
            segmentationType === 'new-segmentation' &&
            stepPublic === 'new-public'
          "
          class="absolute-checkbox"
          v-model="saveAndCreatePublic"
          :checked="saveAndCreatePublic"
          @click="saveAndCreatePublic != saveAndCreatePublic"
        >
          <p class="text-checkbox">Salvar e criar público</p>
        </base-checkbox>
        <base-button
          v-if="stepPublic !== 'public-name'"
          text="Continuar"
          @click="jumpToStepPublic()"
          class="big btn-big"
          :class="{
            'w-100 mb_15 ': isMobile,
            mt_0: stepPublic === 'custom-public' && !createCustomSegmentation,
          }"
          :disabled="segmentationType === '' || !activeNextPass"
        />

        <base-button
          v-if="stepPublic === 'public-name'"
          text="Salvar e continuar"
          @click="nextStep(6)"
          class="big"
          :class="{ 'w-100 mb_15': isMobile }"
          :disabled="!activeNextPass"
        />
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      :title="selectedSegment && selectedSegment.name"
      :key="selectedSegment && selectedSegment.type"
      @onClose="closeDrawer()"
    >
      <div v-if="selectedSegment">
        <div v-if="selectedSegment.type == 'clientAgeRange'">
          <client-age-range
            @save="handleError"
            @click="errorController = true"
          />
        </div>
        <div v-if="selectedSegment.type == 'behavioralDaysOff'">
          <behavioral-days-off
            @save="handleError"
            @click="errorController = true"
          />
        </div>
        <div v-if="selectedSegment.type == 'clientRegisterTimer'">
          <client-register-timer
            @save="handleError"
            @click="errorController = true"
          />
        </div>
        <div v-if="selectedSegment.type == 'clientAverageTicket'">
          <client-average-ticket
            @save="handleError"
            @click="errorController = true"
          />
        </div>
        <div v-if="selectedSegment.type == 'clientOrderQuantity'">
          <client-order-quantity
            @save="handleError"
            @click="errorController = true"
          />
        </div>
        <div v-if="selectedSegment.type == 'clientTotalSpent'">
          <client-total-spent
            @save="handleError"
            @click="errorController = true"
          />
        </div>
        <div v-if="selectedSegment.type == 'regionRange'">
          <region-range
            @save="handleError"
            @click="errorController = false"
          />
        </div>
      </div>
      <template slot="footer">
        <base-button
          text="Salvar"
          class="w-100"
          @click="addSegment(addSegmentations)"
          :disabled="errorController"
        />
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { mapState } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import Utils from '@/utils';

import BaseInput from '@/components/Input';
import BaseLabel from '@/components/Label';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseDrawer from '@/components/Drawer';
import BaseRadio from '@/components/Radio';
import BaseLoading from '@/components/Loading';

import ClientRegisterTimer from '@/containers/Performance/Steps/SegmentationDrawers/client/clientRegisterTimer.vue';
import ClientAgeRange from '@/containers/Performance/Steps/SegmentationDrawers/client/clientAgeRange.vue';
import ClientAverageTicket from '@/containers/Performance/Steps/SegmentationDrawers/client/clientAverageTicket.vue';
import ClientOrderQuantity from '@/containers/Performance/Steps/SegmentationDrawers/client/clientOrderQuantity.vue';
import ClientTotalSpent from '@/containers/Performance/Steps/SegmentationDrawers/client/clientTotalSpent.vue';
import BehavioralDaysOff from '@/containers/Performance/Steps/SegmentationDrawers/behavioral/behavioralDaysOff.vue';
import GeographicDistrict from '@/containers/Performance/Steps/SegmentationDrawers/geographic/geographicDistrict.vue';
import RegionRange from '@/containers/Performance/Steps/SegmentationDrawers/geographic/regionRange.vue';
import NewSegmenter from '@/containers/Performance/SetpsValidator/NewSegmenter.vue';
import { AudienceAPI } from '@/api/AudienceAPI';

const components = {
  BaseInput,
  BaseLabel,
  BaseButton,
  BaseCheckbox,
  BaseDrawer,
  BaseRadio,
  BaseLoading,
  ClientRegisterTimer,
  ClientAgeRange,
  ClientAverageTicket,
  ClientOrderQuantity,
  ClientTotalSpent,
  BehavioralDaysOff,
  GeographicDistrict,
  NewSegmenter,
  InfiniteLoading,
  RegionRange
};

export default {
  components,
  props: {
    loading: { type: Boolean, default: false },
    campaign: { type: Object },
    createCustomSegmentation: { type: Boolean, default: false },
  },
  async mounted() {
    const audiencesResponse = await AudienceAPI.getAudiences(0, 1);
    this.hasAudiences =
      (audiencesResponse.getData({ items: [] }).items || []).length > 0;
  },

  created() {
    // this.segmentationType = '';
    this.checkSementationSelected();
  },
  data() {
    return {
      pagination: {
        next: 0,
        limit: 18,
      },
      activeNextPass: false,
      hasAudiences: false,
      audiences: [],
      activePersonal: true,
      audienceSelected: {},
      rulesTypes: {
        behavioralDaysOff: 'Dias sem pedir',
        behavioralBirthday: 'Aniversariante',
        clientAgeRange: 'Faixa etária',
        clientRegisterTimer: 'Tempo de cadastro',
        clientAverageTicket: 'Ticket médio',
        clientOrderQuantity: 'Quantidade de conversões',
        clientTotalSpent: 'Total gasto',
        geographicDistrict: 'Região',
        regionRange: 'Região',
        weatherPhotography: 'Fotografia climática',
      },

      public: [],
      audienceConfigs: {},
      stepPublic: 'public-options',
      saveAndCreatePublic: false,
      segmentationType: null,
      namePublic: '',
      openedGroup: null,
      selectedSegment: null,
      drawer: { open: false, close: false },
      errorController: true,
      addSegmentations: {},
      tags: [],
      tagControllers: [],
      periodTag: '',
    };
  },
  watch: {
    namePublic(newVal) {
      if (newVal.length > 0) {
        this.activeNextPass = true;
      } else {
        this.activeNextPass = false;
      }
    },
  },
  methods: {
    checkSementationSelected() {
      if (Object.keys(this.campaign).includes('rules')) {
        this.activeNextPass = false;
        this.stepPublic = 'new-public';
        this.segmentationType = 'new-segmentation';
      }
    },
    async loadAudiences($state) {
      const { pagination } = this;

      if (pagination.next === -1) {
        return;
      }

      const audiencesResponse = await AudienceAPI.getAudiences(
        pagination.next,
        pagination.limit
      );
      const audiences = audiencesResponse.getData({ items: [] });
      this.audiences.push(...audiences.items);
      this.pagination.next = audiences.next.page || -1;
      if (this.pagination.next != -1) {
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }
    },
    selectAudience(audience) {
      this.audienceSelected = audience;
      this.activePersonal = !this.activePersonal;
    },

    updateRules(e) {
      this.activeNextPass = e.rules && (e.rules || []).length > 0;
      this.audienceConfigs = e;
    },
    async jumpToStepPublic() {
      if (this.segmentationType === 'custom-public') {
        const { rules, id, name } = this.audienceSelected;
        if (
          Object.keys(this.audienceSelected).length > 0 &&
          this.activePersonal
        ) {
          this.$emit('goToStep', 6, {
            rules,
            audience_id: id,
            audience_name: name,
          });
        } else {
          this.stepPublic = 'custom-public';
        }
      } else if (this.segmentationType === 'new-segmentation') {
        if (this.stepPublic == 'new-public') {
          if (this.audienceConfigs.rules.length > 0) {
            if (this.saveAndCreatePublic) {
              // Se for para salvar o publico como visivel, pular para o step de nome do publico
              this.stepPublic = 'public-name';
              this.activeNextPass = false;
            } else {
              // Salvar o publico e emitir o salvamento
              this.savePublic();
            }
          }
        } else if (this.activeNextPass) {
          this.activeNextPass = false;
          this.stepPublic = 'new-public';
        }
      }
    },
    backStep() {
      this.activeNextPass = (this.audienceConfigs.rules || []).length > 0;
      this.stepPublic =
        this.stepPublic == 'public-name' ? 'new-public' : 'public-options';
    },
    nextStep(step) {
      this.stepPublic = 'public-options';
      this.audienceConfigs.audience_name = this.namePublic;
      this.savePublic();
    },
    async savePublic() {
      this.activeNextPass = false;

      // Se o salvamento do publico estiver tudo ok
      if (this.saveAndCreatePublic) {
        this.audienceConfigs.audience_id = null;
      }
      return this.$emit('goToStep', 6, this.audienceConfigs);
    },
    getSegmentationsOrDefault() {
      return Utils.nullishCoalescing(
        this,
        'campaign.configs.segmentations',
        []
      );
    },
    closeDrawer() {
      this.selectedSegment = null;
      this.drawer.close = !this.drawer.close;
    },
    addSegment(segment) {
      this.campaign.configs.segmentations =
        this.getSegmentationOrDefault().filter((s) => s.field != segment.field);
      this.campaign.configs.segmentations.push(segment);
      this.closeDrawer();
    },
    canSave() {
      return !(
        Utils.nullishCoalescing(this, 'campaign.configs.segmentations', [])
          .length > 0
      );
    },
    save() {
      this.$emit('save', this.campaign);
    },
    handleError(configs) {
      const { minimum, exact, max, min, district, isLast, type } =
        Utils.nullishCoalescing(configs, 'value', {});
      if (
        minimum > 0 ||
        exact > 0 ||
        Number(max) > Number(min) ||
        district !== undefined
      ) {
        configs.comparison = '';
        configs.type = 'FUNCTION';
        this.addSegmentations = configs;
        this.errorController = false;
      } else if (isLast !== undefined && type >= 0) {
        configs.comparison = '';
        configs.type = 'FUNCTION';
        this.addSegmentations = configs;
        this.errorController = false;
      } else {
        this.errorController = true;
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.collapse .option {
  background: rgba(46, 52, 56, 0.65);
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.collapse .option:hover {
  background: #2e3438bd;
}
.collapse .option .chevron {
  position: absolute;
  right: 10px;
  color: var(--icon-inactive);
}
.collapse .option .chevron i {
  transition: all 0.2s;
  font-size: 11px;
}
.collapse .option .chevron.active i {
  transform: rotate(180deg);
}
.collapse .option i {
  font-size: 15px;
  margin-right: 10px;
  color: var(--icon-inactive);
}
.collapse .option p {
  color: var(--label-primary);
  font-size: 12px;
  line-height: 15px;
}
.collapse .content {
  padding: 10px 0 5px 0;
}
.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.tags .tag {
  color: var(--label-secondary);
  font-weight: bold;
  padding: 5px 10px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  border: 1px solid #ffffff17;
  margin-bottom: 5px;
  margin-right: 5px;
}
.coupons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.segmentation-type {
  padding-bottom: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}
.segmentation-type i {
  color: rgba(190, 190, 191, 0.75);
  font-size: 12px;
  margin-right: 15px;
}

.grid-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.publics {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.publics .publics-scroll {
  overflow-y: auto;
  height: 400px;
}

.publics .public {
  background: rgba(46, 52, 56, 0.65);
  padding: 5px;
  border-radius: 10px;
}

.publics .public .content {
  background: rgba(30, 33, 36, 0.3);
  padding: 0 15px 5px;
  border-radius: 10px;
  height: 100%;
}

.publics .public .content .header-public {
  display: flex;
  align-items: baseline;
  padding-bottom: 20px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
  position: relative;
}

.publics .public .content .header-public .name {
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: #cccccc;
  font-weight: bold;
  margin-left: 15px;
}

.publics .public .content .header-public i {
  color: var(--icon-inactive);
  font-size: 15px;
}

.publics .public .content .tags-public {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
.publics .public .content .tags-public .tag {
  background: #252a2e;
  color: rgba(170, 170, 171, 0.7);
  font-weight: bold;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 15px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.2);
  margin-bottom: 10px;
  margin-right: 10px;
}

.publics .publics-infinite-loader {
  height: auto;
}

@media screen and (min-width: 992px) {
  .absolute-checkbox {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .publics .publics-scroll {
    padding-right: 15px;
  }
}

@media screen and (max-width: 991px) {
  .grid-options {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px 20px;
  }
}
</style>
