<template>
  <base-card>
    <base-label text="Defina a faixa etária" />
    <base-radio
      family="age-range"
      option="range"
      class="mt_15"
      @click="handleInputSelected('age-group')"
    >
      <p class="radio-label">Faixa de idade</p>
    </base-radio>
    <div class="d-flex mt_10" v-if="controllerInputs === 'age-group'">
      <base-input
        placeholder="De"
        class="w-50 mb_0"
        v-model="range.min"
        type="tel"
        @change="mountPayload('range')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
      <base-input
        placeholder="Até"
        class="w-50 ml_15 mb_0"
        v-model="range.max"
        type="tel"
        @change="mountPayload('range')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
    </div>

    <base-radio
      family="age-range"
      option="minimum"
      class="mt_15"
      type="tel"
      @click="handleInputSelected('age-min-range')"
    >
      <p class="radio-label">Idade mínima</p>
    </base-radio>
    <div class="d-flex mt_10" v-if="controllerInputs === 'age-min-range'">
      <base-input
        placeholder="A partir de"
        class="w-100 mb_0"
        v-model="exact"
        type="tel"
        @change="mountPayload('minimum')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
    </div>

    <base-radio
      family="age-range"
      option="exact"
      class="mt_15"
      @click="handleInputSelected('age-exact')"
    >
      <p class="radio-label">Idade exata</p>
    </base-radio>
    <div class="d-flex mt_10" v-if="controllerInputs === 'age-exact'">
      <base-input
        v-if="controllerInputs === 'age-exact'"
        placeholder="Defina a idade"
        class="w-100 mb_0"
        v-model="exact"
        type="tel"
        @change="mountPayload('exact')"
        @keyup="mountPayload('exact')"
        :thousandSeparator="false"
        :moneyMask="StringLanguage.timeConfigs.years"
      />
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/Card';
import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseCheckbox from '@/components/Checkbox';
import BaseLabel from '@/components/Label';
import BaseRadio from '@/components/Radio';

const components = {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseLabel,
  BaseRadio,
  BaseCard,
};

export default {
  components,
  data() {
    return {
      number: 'range',
      range: {},
      exact: '',
      minimun: '',
      isLast: '',
      type: '',
      controllerInputs: '',
    };
  },
  methods: {
    handleInputSelected(value) {
      this.controllerInputs = value;
      this.exact = '';
      this.range = {};
    },

    mountPayload(type) {
      const ACTION_TYPE = {
        DAY: 1,
        MONTH: 2,
        YEAR: 3,
      };

      const payload = {
        field: 'clientAgeRange',
        value: { type: 'clientAgeRange' },
      };

      if (type === 'range') {
        payload.value = this.range;
      }
      if (type === 'exact' || type === 'minimum' || type === 'isLast') {
        payload.value = { [type]: this.exact };
      }
      if (type === 'isLast') {
        payload.value = { isLast: this.exact, type: ACTION_TYPE[this.type] };
      }
      this.$emit('save', payload);
    },
  },
  watch: {
    exact() {
      this.mountPayload('exact');
    },
    'range.max': function () {
      this.mountPayload('range');
    },
  },
};
</script>
