<template>
  <div class="d-flex flex-column justify-between h-100">
    <p class="title">Deve ser disparado entre</p>
    <div class="w-50 pr_10">
      <base-select
        :items="timeIntervalOptions"
        v-model="configs.send_time.time"
      />
    </div>
    <base-checkbox v-model="machineLearning">
      Aprendizagem automática
    </base-checkbox>

    <contemplated
      :campaign="configs"
      @onSaveContemplated="handleSaveContemplated"
    />

    <div class="d-flex justify-end mt_30">
      <base-button
        text="Continuar"
        @click="save()"
        class="big"
        :class="{ 'w-100': isMobile }"
        :disabled="!validate()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Contemplated from '@/containers/Performance/Trigger/Contemplated';
import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';
import BaseSelect from '@/components/Select';
import BaseCheckbox from '@/components/Checkbox';

const components = {
  Contemplated,
  BaseDrawer,
  BaseButton,
  BaseSelect,
  BaseCheckbox,
};

export default {
  components,
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.loadFiring(this.campaign);
  },
  data() {
    return {
      payload: {},
      machineLearning: false,
      drawer: { open: false, close: false },
      timeIntervalOptions: this.getTimeIntervalOptions(),
      configs: {
        send_time: {
          time: null,
        },
        interval: 0,
        single_conversion: true,
      },
    };
  },
  methods: {
    loadFiring(campaign) {
      campaign = JSON.parse(JSON.stringify(campaign));
      this.configs = {
        send_time: campaign.configs.send_time
          ? campaign.configs.send_time
          : { time: null },
        interval: campaign.configs.interval || null,
        single_conversion: campaign.configs.single_conversion,
      };
    },

    handleSaveContemplated(configs) {
      this.configs.interval = configs.interval;
      this.configs.single_conversion = configs.single_conversion;
    },

    validate() {
      const [time] =
        this.configs.send_time && this.configs.send_time.time
          ? this.configs.send_time.time.split(':')
          : [];

      return time && +time >= 0;
    },

    getTimeIntervalOptions() {
      const horaries = [];

      for (let i = 0; i <= 22; i++) {
        horaries.push({
          value: `${String(i).padStart(2, '0')}:00`,
          text: `${String(i).padStart(2, '0')}h - ${String(i + 1).padStart(
            2,
            '0'
          )}h`,
        });
      }

      return horaries;
    },
    save() {
      this.$emit('onSaveFiring', this.configs);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    campaign: {
      deep: true,
      handler(campaign) {
        this.loadFiring(campaign);
      },
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bebebf;
  margin-bottom: 10px;
}
</style>
